.sidenav {
  height: calc(100vh - 150px) !important;
  /* height: 100%; */
  width: 0;
  position: fixed;
  z-index: 2;
  top: 105px;
  right: 0;
  background-color: #282b30;
  /* overflow-x: hidden; */
  /* overflow-y: auto !important; */
  transition: .5s;
  padding-top: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 2px #282b30;
}

.sidenav .kpiMenu {
  padding: 10px 8px 10px 64px;
  text-decoration: none;
  font-size: 15px;
  /* font-weight: normal; */
  color: #f1f1f1;
  display: block;
  transition: .1s;
  cursor: pointer;

}


.sidenav .kpiMenu:hover {
  /* color:#818181 !important; */
}

.closebtn,
.containerClosebtn,
.containerPinbtn,
.containerUnPinbtn {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  right: 10px;
  margin-left: 50px;
  cursor: pointer;
}

.containerClosebtn {
  top: 25px !important;
}

.containerPinbtn,
.containerUnPinbtn {
  right: 40px;
  width: 20px !important;
  top: 23px !important;
  /* height: 15px !important; */
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav .kpiMenu {
    font-size: 18px;
  }
}

.kpiMenuBtn,
.capaWorkPlanMenuBtn {
  font-size: 30px;
  cursor: pointer;
  float: right;
  position: fixed;
  right: 0;
  z-index: 1;
  top: 110px;
}

@media only screen and (min-width: 700px) and (max-width: 1210px) {

  .kpiMenuBtn,
  .capaWorkPlanMenuBtn {
    right: 25px;
  }

  .SliderForLayout {
    width: 20% !important;
  }
}


@media only screen and (min-width: 1210px) and (max-width: 1410px) {

  .kpiMenuBtn,
  .capaWorkPlanMenuBtn {
    right: 25px;
  }

  .SliderForLayout {
    width: 25% !important;
  }
}

@media only screen and (min-width: 1411px) and (max-width: 1920px) {

  .kpiMenuBtn,
  .capaWorkPlanMenuBtn {
    right: 7px;
  }

  .SliderForLayout {
    width: 30% !important;
  }
}

.capaWorkPlanMenuBtn {
  top: 160px !important;
  z-index: 1;
}

.kpiheadingDiv,
.capaWorkPlanheadingDiv {
  padding-top: 10px;
  /* position: fixed; */

}

.kpiIcon {
  width: 25px;
  height: 25px;
  padding: 10px;
  background-color: #282b30;
}

.kpiMenuHeading {
  font-size: 24px !important;
  /* margin-left: 30px; */
  margin-top: -25px;
  padding-left: 64px;
  display: block;
  transition: 0.1s;
}

.kpiEditMenuHeading,
.capaWorkPlanPanelHeading {
  font-size: 17px !important;
  font-weight: bold;
  /* margin-left: 30px; */
  margin-top: 4px;
  padding-left: 20px;
  display: block;
  transition: 0.1s;
  width: 85%;
  color: #f1f1f1;
}

.kpiEditMenuHeadingLabel {
  font-size: 16px !important;
  font-weight: bold;
  /* font-family: "Roboto-normal"; */
  font-style: normal;
  display: block;
  transition: 0.1s;
  width: 85%;
  color: #f1f1f1;
}

.kpiEditMenuHeadingDescription {
  font-size: 15px !important;
  margin-left: 20px;
  margin-top: 20px;
  /* padding-left: 50px; */
  display: block;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  width: 85%;
  height: 30px;
  color: #818181;

}

.disabled {
  cursor: help !important;
  user-select: none;
  /* pointer-events: none; */
  opacity: 0.1;
}

.kpiMenuHeadingIcon {
  width: 22px;
  height: 22px;
  padding-right: 5px;
  padding-left: 22px;
  background-color: #282b30;
  vertical-align: middle !important;
}

.kpiMenuHeading:hover,
.kpiMenuHeadingIcon:hover {
  /* color:#818181 !important; */
  cursor: pointer;
}

.horizontalmenuline {
  border-bottom-style: solid;
  border-bottom-width: thin !important;
  border-color: #818181;
  display: block;
  /* margin-left: 10px ;
    margin-right: 10px; */
  margin-top: 15px;
}

.dashBoardNavMenuDiv {
  padding-top: 35px;
}

.editFieldDiv {
  margin-top: 20px;
  margin-left: 10px;
}

.dataSourceSelect,
.visualTypeSelect,
.capaMachineSelect,
.capaLocationSelect {
  margin-top: 20px;
  margin-left: 0px;
}

.colorChooser {
  margin-top: 20px;
  margin-left: 5px;
}

.colorPaletteCombo {
  margin-top: 20px;
}

.editLabel {
  /* margin-top: 10px; */
  padding: 10px;
  width: 175px;

}

.editLayoutField,
.widgetEditLabel {
  width: 300px;
}

.dataSourceLabel,
.visualType,
.colorPaletteLabel,
.flavorMenu,
.capaMachineType,
.capaPlantLocationType {
  padding-left: 20px;
}

.baseColorLabel {
  padding-left: 15px;
}

.itemDataSource,
.visualize,
.colorPaletteChooser {
  /* padding: 10px; */
  margin-left: 20px;
  margin-top: -10px;
}

/* .baseColorLabel{
padding-left: 15px;
} */
.LiColorPicker_ParentNode {
  margin-left: 15px;
  margin-top: 10px;
}

#LiCombo_container\ dataSource,
#LiCombo_container\ visualType,
#LiCombo_container\ colorPalette,
#LiCombo_container\ fonstSizeType,
#LiCombo_container\ labelDisplay,
#LiCombo_container\ capaMachine,
#LiCombo_container\ capaPlantLocation {
  width: 175px;
  height: 30px;
}

.saveKPI {
  margin-top: 5px;
  float: right;
  margin-bottom: -50px;
}


.horizontalSaveKPIline {
  border-bottom-style: solid;
  border-bottom-width: thin !important;
  border-color: #818181;
  display: block;
  margin-top: 25px;
}


.LiLineEdit_main.editDataField {
  margin-top: 20px;
  margin-left: 10px;
  width: 200px;
}

/****************************************************************************************************************/
.LightTheme.sidenav,
.LightTheme.kpiMenuHeadingIcon,
.LightTheme.kpiMenu,
.LightTheme.kpiIcon,
.LightTheme.capaWorkPlanPanelHeading,
.LightTheme.capaWorkPlanMenu {
  background-color: #cacbcd !important;
  color: black !important;
}

.Dashboard_EditContentArea {
  /* height: 70vh !important; */
  /* overflow-y: auto; */
  position: relative;
}

.Dashboard_EditContentWrapper {

  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 150px);
  overflow-y: auto;
}

.Dashboard_settingsComponentsDiv {
  /* border: 0.5px solid black; */
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.dataSettingsCombo {
  padding-top: 10px;
}

.react-tags {
  margin: 5px;
  padding: 5px;
  padding-left: 55px;
}

.react-tags__search-input {
  width: 97% !important;
}

*************************************************************************************************************************************************************************** .react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  /* padding: 6px 8px; */
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  /* border-color: green; */
  background-color: lightblue;
  cursor: pointer;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 2px;
  border: 0;
  outline: none;
  width: 250px !important;
  height: 20px;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: lightblue;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: lightblue;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.containerDiv {
  /* border: 1px solid red;
  width: 80%; */
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100vh - 50%); */
  height: 80%;
}

.containerTags {
  margin-left: 50px;
  padding-bottom: 10px;
}

.widgetMenuIcon {
  width: 24px;
  height: 24px;
  /* padding-left: 10px; */
  /* padding-right: 10px;
  margin-left: 15px;
  margin-bottom: -6px;
  margin-top: 10px; */
}

.containerDiv .widgetMenuIcon {
  width: 24px;
  height: 24px;
  padding-right: 10px;
  margin-left: 15px;
  margin-bottom: -6px;
  margin-top: 10px;
}

.widgetTrendIcon {
  width: 25px;
  height: 25px;
  /* padding-left: 5px; */
  padding-right: 5px;
}

.showChartHeaderText,
.showTableColSearch,
.showTableColSort,
.showTableConfig,
.showTarget,
.showTableTrigger {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.LightTheme.kpiEditMenuHeading,
.LightTheme.kpiEditMenuHeadingLabel,
.LightTheme.kpiEditMenuHeadingDescription {
  color: #000000;
}

.containerType {
  font-size: 15px;
  font-weight: bold;
}

.settingFields {
  padding-bottom: 25px;
}

.settingLegend {
  color: white;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  /* font-family: 'Roboto-normal'; */
}

.LightTheme.settingLegend {
  color: black;
}

.dashboardLayoutEditButtonsDiv {
  /* border:1px solid green; */
  /* margin-top: 45%; */
  width: 100%;
  position: absolute;
  height: 30px;
  bottom: 9%;
  right: 0;
}

.widgetLabelEditIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-top: 3px;
  padding-left: 5px;
  opacity: 1;
}

.textAreaClass {
  margin-left: 20px;
}

.palettes {
  width: 65px;
  height: 12px;
}

/* Filter Panel */
.kpiFilterPanelHeading,
.capaWorkPlanPanelHeading {
  font-size: 24px !important;
  /* margin-left: 30px; */
  /* margin-top: -25px; */
  padding-left: 20px;
  display: block;
  transition: 0.1s;

}

.filternav,
.capaWorkPlannav {
  height: 60% !important;
  width: 20% !important;
}

.targetDefinitionText {
  width: 75%;
}

.capaWorkPlanMainDiv {
  margin-left: 15px;
}

.capaWorkPlannav {
  transition: 0.1s !important;
  top: 160px !important;
  z-index: 1 !important;
}

.capaWorkPlanMenuDiv {
  overflow-y: auto;
  height: inherit;
}

.capaWorkPlanMenu {
  padding: 10px 8px 10px 2px;
  text-decoration: none;
  font-size: 15px;
  /* font-weight: normal; */
  color: #f1f1f1;
  display: block;
  transition: .1s;
  cursor: pointer;

}

.capaWorkPlanDiv {
  height: inherit;
  overflow-y: auto;
}

.generateLayoutBtn {
  /* padding: 10px; */
  /* width: 128px; */
  margin-top: 5px;
  cursor: pointer;
}

.CapaVisualActionMenu .LiActionMenu_Container {
  position: static;
}

.CapaVisualActionMenu>span>.actionIconVertical {
  height: 15px !important;
  padding-top: 10px;

}

.toLowerCase{
  text-transform: lowercase;
}