.singleValue {
  width: 100%;
  /* padding: 5% 2%; */
  /* text-align: center;
  font-size: x-large;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  white-space: normal; */
  /* position: absolute; */
  /* top: 50%;
  transform: translateY(-50%); */

  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  /* padding: 10px; */
  text-align: center;
  position: absolute;
  /* height: inherit; */
}

/* .DarkTheme.singleValue{
    color: purple;
  } */
/* .singleValue2{
    width: 100%;
    min-height: 80px;
    background-color: #881B4c;
    display: flex;
    align-items: center;
  }
  .DarkTheme.singleValue2{
    background-color: #d17905;
  } */
.singleContentHead {
  color: black;
  padding-left: 25px;

}

/* .singleContentValue{
    color:seagreen;
   font-weight: 900;
   font-size: x-large;
   padding-left: 25px;
  }  */
/* .DarkTheme.singleContentValue{
   
    color:#04c9b7 !important;
  } */

.DarkTheme.singleContentHead {
  color: white;
}

.singleContentValue {
  /* color:seagreen; */
  /* font-weight: 900; */
  /* font-size: x-large; */
  /* padding-left: 25px; */
  /* position: inherit; */
  text-overflow: ellipsis;
  overflow: hidden;
}


label.textTodaysDate {
  font-size: 12px;
  display: block;
  /* float: right; */
  /* margin-top: 4px; */
  /* float: right; */
  margin-top: 9px;
}

.singleValueimg img.graphicalArea {
  width: 100%;
  height: 100%;
}

img.graphicalView {
  width: 100%;
  height: 90%;
}

.graphicalAreaWidget {
  width: 100%;
  /* height: 100%; */
  height: calc(100% - 45px) !important;
  top: 50%;
  padding: 5px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  text-align: center;
  position: absolute;
}

.widgetSelectedclass {
  border: 1px solid rgb(64, 128, 193) !important;
  box-shadow: 0px 0px 10px rgb(64, 128, 193) !important;
  cursor: pointer !important;
}