.ui-dialog{
    z-index: 999 !important;
}

.GenericTrigger_Container {
    height: 30px;
    position: relative;
    top: -4px;
    left: 0%;
}

.title {
    width: 93%;
}

.title > span {
    float: left;
}

.GernericTrigger_button{
    padding: 0 !important;
    height: inherit !important;
}

.DarkTheme.GernericTrigger_button,
.DarkTheme.material-iconsGernericTrigger_button{
    color: white !important;
}

.LightTheme.GernericTrigger_button{
    color: black;
}

.DashboardTriggerMain{
    height: 100%;
}