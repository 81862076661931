.dashboardWidget{
    width:100px;
    min-height: 100px;
    border:1px solid red;
}


.TriggerPopupDashboard {
    z-index: 99999999999;
    position: fixed; /* Stay in place */
    /* z-index: 1; */ /* Sit on top */
    top: 0;
    /* padding-top: 100px; */ /* Location of the box */
    left: 50%;
    top: 10px;
    width: 20%; /* Full width */
    height: 74%; /* Full height */
    background-color: white;
    overflow: auto; /* Enable scroll if needed */
    /* background-color: rgb(0,0,0); */ /* Fallback color */
    /* background-color: rgba(0,0,0,0.4); */ /* Black w/ opacity */
}

.TriggerPopupDashboard img {
    width: 45px;
}

.TriggerPup {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 2; 
    /* background: var(--darktheme-white-variant1); */
}

.LightTheme.actionTriggerPopupClickable {
    color: var(--white) !important;
    min-height: 30px !important;
    /* background-color: #D3D3D3 !important; */
    background-color: var(--darktheme-white-variant5)!important;
}

.DarkTheme.actionTriggerPopupClickable {
    color: var(--black) !important;
    min-height: 30px !important;
    /* background-color: #30373d !important; */
    background-color: var(--lighttheme-black-variant4) !important;
}

.LightTheme.actionTriggerPopupClickable:hover {
    /* background-color: var(--claret-violet) !important; */
    background-color: var(--lighttheme-tufts-blue-variant1) !important;
    color: var(--white) !important;
}

.DarkTheme.actionTriggerPopupClickable:hover {
    background-color: var(--darktheme-tufts-blue-variant2) !important;

}