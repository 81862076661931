/* .canvasH{
    height: 60vh;
    width: 100%;
    display: inline-block;
    
} */

.chartValue{
    /* width: 95%;
    height: calc(100% - 50px)!important;
    min-height: 250px; */
    /* color: orangered; */
    /* display: flex; */
    align-items: center;
    /* margin-top: 20px; */
    /* position: absolute; */
  }

  .canvasH{
      margin-top: 7px !important;
      height: 90% !important;
      /* width: 97% !important; */
      /* display: inline-block; */
      width: 94% !important;
      position: absolute !important;
      margin-bottom: 40px !important;
  }
  .settingsIcon{
      /* margin-left: 50%;
      margin-top: 25%; */
      width:100%;
      height: 50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  }

  
  .chartjs-render-monitor{
      /* height: 100% !important; */
  }


  .dashboarChartItem{
    height: calc(100% - 30px)!important
  }