div#LiLayout_contentIn {
  height: 100%;
}

.dashboardMainLayout {
  /* overflow-x: auto;
  overflow-y: auto; */
  /* width:99%; */
  /* display: inline-block; */
  /* height: calc(100vh - 60px)!important; */
  /* right: 10px !important; */
  height: 100%;
}

.editLayout {
  /* padding-top: 35px ;
  margin-left:25px; */
  position: relative;
  display: inline-block;
  /* height: calc(100vh - 125px)!important; */
  width: 100%;
  height: 100%;
  /* height: calc(100vh - 64px)!important; */
  height: 95%;
}

.editLayout .gridLayout .dashboardGridLayout {
  min-height: 2155px;
  min-width: 3865px;
  padding-top: 0px;
  grid-template-columns: repeat(12, 1fr);
  margin: 0;
}

.dashboardGridLayout {
  padding-top: 30px;
  margin-left: 25px;
  position: relative;
  display: block;
  width: 98% !important;
  height: 95% !important;
  /* grid-template-columns: repeat(12, 1fr); */
}

.dashboardGridLayoutDiv {
  /* width: max-content; */
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  /* display: inline-block; */
  height: 88% !important;
  position: relative;

}

.editLayout .dashboardGridLayoutDiv {
  margin-left: 20px;

}

.dashboardLayoutControlsDiv.hideTopPanel {
  display: none;
}

.viewWidget {
  cursor: auto;
  position: relative;
  margin: 5px 5px;
  height: inherit;
}

.hideWidget {
  display: none !important;
}

.DarkTheme.viewWidget {
  background-color: var(--darktheme-black-variant2);
}


.LightTheme.viewWidget {
  background-color: var(--lighttheme-black-variant4) !important;
  color: black !important;
  /* font-weight: bold !important; */
}

.widget {
  /* background-color: #282b30; */
  cursor: auto;
  position: relative;
  /* width:85px !important;
    height: 85px !important; */
  /* border: 1px solid black; */
}

.draggableArea:hover {
  box-shadow: 0 2px 10px #282b30;
}


.dropbtn:hover,
.dropbtn:focus {
  position: relative;
  z-index: 9999;
}

.widgetSpan {
  font-size: smaller;
  padding-left: 10px;
  top: 10px;
  cursor: auto;
  position: relative;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 90px);
  float: left;
}

.view {
  width: calc(100% - 50px);
}

.actionDeleteSpan,
.refreshSpan {
  float: right;
  text-align: center;
  width: 60px;
  padding-top: 7px;
}

.widgetDeleteIcon {
  height: 10px;
  width: 10px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 10px;
  float: right;
  opacity: .3;
  opacity: 1;
}

.widgetEditIcon,
.widgetFilterIcon,
.widgetCopyIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-top: 3px;
  /* opacity: .3; */
  opacity: 1;
}

.widgetCopyIcon {
  padding-right: 5px;
}

span.DarkTheme.view.filterSpan>img.widgetFilterIcon {
  padding-top: 10px;

}

.widgetFilterIcon {
  padding-top: 8px;
  padding-left: 5px;
}

.widgetRefreshIcon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 10px;
  float: right;
  /* opacity: .3; */
  opacity: 1;
}

.view .widgetRefreshIcon {

  margin-top: -10px;

}

.widgetEditIcon:hover,
.widgetDeleteIcon:hover {
  /* opacity:1 !important; */
}

.react-grid-item {
  /* margin-left:25px !important; */
  /* min-width: 85px !important;
    min-height: 85px !important; */
}

/***************************************************************************************************************************************************/
.LightTheme.widget {
  /* background-color: rgba(0, 0, 0, 0.05) !important; */
  background-color: var(--lighttheme-black-variant4) !important;
  color: black !important;
  /* font-weight: bold !important; */
}

.LightTheme.widgetSpan {
  color: black !important;
}

.DarkTheme.widget {
  color: white !important;
  /* font-weight: bold !important; */
  background: var(--darktheme-black-variant2);
  /* padding: 5px; */
}

.widget .LiTable,
.widgetValueDiv .LiTable {
  font-weight: normal !important;

}

.singleValueTable .LiTable {
  font-family: 'Roboto-Regular', Arial, sans-serif !important;
}

.DarkTheme.widgetSpan {
  color: white !important;
}

/* .DarkTheme.paginationBar{
    background-color:#353D44 !important;
  } */
.dashboardGridLayout {
  /* display: grid; */
  /* border: 2px solid rgb(137,153,175); */
  /* grid-template-columns: 100px 100px 100px;
    grid-template-rows: 100px 100px 100px; */
  /* grid-gap: 100px; */

}

.dashboardGridLayout>* {
  /* border: 2px solid rgb(137,153,175); */
}

.dashboardGridLayout::before {
  content: "";
  /* background-color: rgb(214,232,182); */
  /* grid-column: 3;
    grid-row: 1; */
}

.dashboardGridLayout::after {
  content: "";
  /* background-color: rgb(214,232,182); */
  /* grid-column: 1;
    grid-row: 3; */
}

/***********************************************************************************************************************************************************/
.gridLayout {
  /* position: relative; */
  /* padding:0px 0px 0px 0px; */
  /* flex:1 1 auto; */
  /* min-height: 2155px;
    min-width: 3865px; */
}

.gridBoard {
  /* padding: 16px 40px; */
  /* overflow: auto; */
  flex: 1 1 auto;
}

.gridLayout>.gridLayout-childcontainer {
  position: relative;
  transition: height .25s linear, width .25s linear 0s
}

.gridLayout>.gridLayout-childcontainer>.gridLayout-element {
  position: absolute
}

.gridLayout>.gridLayout-childcontainer>.gridLayout-drop-placeholder {
  position: absolute;
  background: rgba(0, 0, 0, .2)
}

/*.gridLayout-childcontainer>.fxs-tile{transition:height 125ms linear 125ms,width 125ms linear 0s,top 175ms ease-out,
  left 175ms ease-out,right 175ms ease-out}*/
.gridLayout-resolution {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  color: #fff;

  border-color: #fff;
  opacity: .7;
  border-width: 1px;
  border-right-style: dashed;
  border-bottom-style: dashed;
  pointer-events: none;
  font-size: 13px;
  line-height: normal;
  overflow-x: auto;
  /* margin-left: 15px; */
}

.gridLayout-resolution::after {
  position: absolute;
  right: 8px;
  bottom: 5px
}

.gridLayout-resolution-1024x768 {
  height: 768px;
  width: 1024px
}

.gridLayout-resolution-1024x768::after {
  content: "1024 x 768"
}

.gridLayout-resolution-1366x768 {
  height: 768px;
  width: 1366px
}

.gridLayout-resolution-1366x768::after {
  content: "1366 x 768"
}

.gridLayout-resolution-1440x900 {
  height: 900px;
  width: 1440px
}

.gridLayout-resolution-1440x900::after {
  content: "1440 x 900"
}

.gridLayout-resolution-1920x1080 {
  height: 1080px;
  width: 1920px
}

.gridLayout-resolution-1920x1080::after {
  content: "1920 x 1080"
}

.gridLayout-resolution-3840x2160 {
  height: 2160px;
  width: 3840px;
  /* margin-left: 20px; */
  margin-top: -5px;
  /* background: url('../../../../Grids 3840 x 2160-01.svg'); */
  /* background: url('../images/grid-tile-background.jpg'); */
  overflow-x: auto;
}

.DarkTheme.gridLayout-resolution-3840x2160 {
  background: url('../images/grid-tile-background-dark.jpg');
  /* background: url('../images/grid-tile-background-dark.png'); */
  /* background: url('../images/grid-tile-background-dark-40.jpg'); */
  /* background: url('../images/grid-tile-background-dark-40.png'); */

}

.LightTheme.gridLayout-resolution-3840x2160 {
  background: url('../images/grid-tile-background-light.jpg');
  /* background: url('../images/grid-tile-background-light.png'); */
  /* background: url('../images/grid-tile-background-light-40.jpg'); */
  /* background: url('../images/grid-tile-background-light-40.png'); */
}

.gridLayout-resolution-3840x2160::after {
  content: "3840 x 2160"
}

.fxs-drag-surface {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: transparent;
  pointer-events: none;
  z-index: 199
}

.fxs-drag-disableTouchAction {
  -ms-touch-action: none;
  touch-action: none
}

.gridLayout-resizehandle {
  display: inline-block;
  padding: 0 4px 4px 0;
  bottom: 0;
  right: 0
}

.gridLayout-resizehandle>div {
  height: 12px;
  width: 12px;
  border: 2px solid rgba(125, 125, 125, .5);
  border-left-width: 0;
  border-top-width: 0
}

.gridLayout-livepreview,
.gridLayout-resizehandle {
  position: absolute;
  cursor: se-resize;
  z-index: 1
}

.gridLayout-livepreview {
  min-width: 85px;
  min-height: 85px;
  max-width: 2245px;
  max-height: 2245px;
  overflow: hidden
}


.LightTheme.gridLayout-resolution {
  color: black;
  border-color: black;
}

.react-grid-item.react-grid-placeholder {
  background: white !important;
}

.LightTheme .react-grid-item.react-grid-placeholder {
  background: darkgray !important;
}

.dashboardLayoutControlsDiv {
  padding-top: 25px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.newDashboard {
  padding: 8px !important;
}

.saveLayout {
  /* display: block; */
  /* align-content: center; */
  /* margin: 25px; */
  display: inline-block;
  width: 100%;
  text-align: center;

}

.saveLayoutLabel {
  float: left;

  padding-left: 10%;
  padding-top: 5px;

}

.saveLayoutField {
  padding-left: 25px;
  float: left;
  margin-bottom: 15px;
}

.saveInner {
  height: 100%;
  width: 55%;
  margin: 0 auto;
}

/* .react-resizable-handle{
  background-image: url(../images/DarkTheme/expansion.svg) !important;
} */
/* .dashboardMainLayout .DarkTheme.widget span.react-resizable-handle{
  position: absolute;
    width: 20px;action
    height: 20px;
    bottom: 0;
    right: 0;
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  background-image: url(../images/DarkTheme/expansion.svg) !important;
} */

/* .dashboardMainLayout .LightTheme.widget span.react-resizable-handle{
  position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  background-image: url(../images/DarkTheme/expansion.svg) !important;
} */
/* .react-grid-item > .react-resizable-handle::after{
  content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 0px !important;
     border-bottom: 0px !important;
} */

.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
}

.layoutName {
  padding: 5px;
  font-weight: normal;
  color: white;
  /* border:1px solid beige ; */
  /* background-color: black; */
}

/* .editLayout .ui-dialog-container .ui-dialog {
  min-height: 200px !important;
} */
/* .editLayout .ui-dialog-container .ui-dialog .ui-dialog-content{
  height: 100% ;
  min-height: 100px !important;
} */



.widgetTopDiv {
  /* height: 40px; */

  /* border: 1px solid red; */

  /* display: inline-block; */
  /* width: 100%;
  height: 100%; */
  height: 30px;
}


.widgetValueDiv {
  /* display: flex;
  justify-content: center;
  align-items: center; */


  /* border: 1px solid green; */
  /* width: 100%;
  position: relative;
  height: calc(100% - 30px); */
  width: 100%;
  position: relative;
  height: calc(100% - 30px);
}

/* .widgetValueChartTableDiv{
  width: 100%;
  position: relative;
  height: calc(100% - 30px);
  overflow-y: auto;
} */

.editLayoutField .LiLineEdit {
  height: 28px;
}

.dashboardLayoutButtonsDiv {
  margin-left: 20px;
  /* margin-top: -5px;  */
}

.editLayout .dashboardLayoutControlsDiv .dashboardLayoutButtonsDiv {
  margin-left: 20px;
  margin-top: -5px !important;
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1600px) {
  .Dashboard_EditContentWrapper {
    height: 75% !important;
  }
}

.dashboardLayoutControlsDiv .LiCombo_main {
  width: 300px;
  height: 35px;
  margin-top: -4px;
}



.singleValueTable {
  width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
  top: -2px !important;
  transform: translateY(0px) !important;
  /* width: 100%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      box-sizing: border-box;
      padding: 10px;
      text-align: center;
      position: absolute; */


}

.tableSettingsIcon {
  height: 50px;
}

.refreshLabel {
  color: #4080c1;
}

.LightTheme.refreshLabel {
  color: #881B4C;
}

#autoRefresh {
  width: 295px !important;
  height: 350px !important;
}

.refreshContainer {
  display: grid !important;
  padding: 5px;
}


.autoRefreshDiv {
  display: inline-block;
}

.widgetAutoRefreshDiv {
  margin-top: 15px;
  margin-left: 15px;
}

/*  FilterPanel Style*/
.filterBaseCombo {
  width: 250px !important;
}

#filter {
  min-width: 250px;
  min-height: 75px;
  width: 100%;
  height: 100%;
}

.editFilterFieldDiv {
  margin-top: 20px;
  margin-left: 15px;
  width: 85%;
}

.editFilterDataField {
  margin-left: 5px;
  /* width: 250px; */
}

/* .filterComboDiv {
    padding-top: 10px;
    margin-left: 5px;
    width: 100%;
} */

.filterpanelBtnDiv {
  float: left;
}

.filterPanelCombo {
  margin-left: 20px;
  margin-top: -10px;
  width: 250px !important;
}

.filterBtnGrp {
  float: left;
  margin-top: 25px;
  margin-left: 110px;
}

.filterDiv {
  /* padding-left: 35px; */
  padding-top: 20px;
  height: 70%;
  /* overflow-y: auto; */
}

.addFilter,
.newFilter,
.saveFilter {

  cursor: pointer;
  /* width:70px; */
  font-size: small;
  margin-left: 15px;
}

.newFilter {
  /* margin-top: 5px !important;
  margin-left: 50px !important; */
  /* right: 21px; */
  right: 20% !important;
  float: right !important;
  margin-top: -30px !important;
  margin-right: 5px !important;
  position: none !important;
  /* position: relative; */

}

.saveBtnFilter {
  cursor: pointer;
  width: 20px;
  height: 30px;
  /* font-size: small; */
  margin-top: -42px !important;
  /* margin-left: 50px !important; */
  right: 21px;
  /* position: relative; */
  float: right;

}

.filterApplyBtn>span>button,
.newFilter>span>button {
  min-width: 30px !important;
  padding: 0px;
  /* box-shadow: 3px 3px 3px grey; */
}

img.buttonImgPadding {
  width: 20px;
}

.filterComponent {
  border-bottom: 1px solid rgba(125, 125, 125, .5);
  padding-bottom: 20px;
  padding-top: 20px;
  /* display: flex; */
  margin-left: 5px;
  /* align-items: flex-end; */
  /* flex-wrap: wrap; */
  /* width:100% */

}

.filterFlavorList {
  padding-left: 10px;
}

.addFilter,
.saveFilter {
  color: #4080c1;
}

.addSaveBtnDiv {
  display: flex;
  padding-left: 20px;
  ;
}

.filterClearIcon,
.filterNewIcon {
  height: 20px;
  width: 15px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px;
  float: left;
  opacity: .3;
  opacity: 1;
  display: inline-block;

}

/* div#filterBtn {
  float: right;
} */

.saveFilterSettings {

  /* display: flex; */
  /* align-items: flex-start; */
  /* margin-left: 30px; */
  margin-top: -10px !important;
  /* float: left; */
}

.widgetValueDiv .saveFilterSettings {
  bottom: 15%;
  margin-bottom: -25px;
  margin-left: 25px;
  /* position: fixed; */
}

.saveFilterDataField {
  margin-right: 10px;
  width: 250px;
  height: 50px;
}

.basicFilterCompDiv {
  display: block;
  align-items: center;

  padding-bottom: 20px;
  /* padding-left: 50px; */
  margin-left: 5px;
  /* width: 100%; */
}

.parentChildDivider {
  border-bottom: 2px solid rgba(125, 125, 125);
  margin-left: 10px;
  margin-right: 10px;
}

.dashboardAsTriggerDiv {
  display: inline-block;
}

div#dashboardasTrigger {
  width: 250px;
  height: 330px;
}

.dashboardTriggerContainer {
  display: grid;
  grid-row-gap: 34px;
  margin-bottom: 58px;
}

.filterFlavorCombo {
  width: 250px;
}

.filterWidgetDiv {
  height: 100%;
  /* overflow: auto; */
}

.filterWidgetWithApplyDiv {
  height: 100%;
}

.filterWidget {
  height: 90%;
}

#filterPanelMainDiv {
  height: 100%;
}

.filterChildComponentsDiv {
  height: 65%;
  overflow-y: auto;
}

.LiCombo_main.openTypeDashBoardTrigger {
  width: 170px;
}

.actionTriggerPopupClickable img.LiTrigger_typeIcon {
  width: 30px;
  height: 25px;
}

.dontShow {
  opacity: 0;
}


.show {
  opacity: 1 !important;
}

.chartDataItem {
  height: calc(100% - 30px);
  box-sizing: border-box;
}

/* .DarkTheme.actionTriggerPopupClickable {
  background-color: #3A3F44 !important;
} */

/**************************** Filter Widget Styling *******************************/
/* 
.filterWidget .filterFlavorCombo{
  width: 75% !important;
}
.filterWidget .filterPanelCombo{
width: 75% !important;

}
.filterWidget .filterBaseCombo {
  width: 75% !important;
}
.filterWidget .saveFilterDataField{
  width:75% !important;
}
.filterWidget .editFilterDataField{
  width: 75% !important;
}
.filterWidget .newFilter{
  right:20% !important;
  float: right !important;
  margin-top: -30px !important;
  margin-right: 5px !important;
  position: none !important;
}
.filterWidget .saveBtnFilter{
  right:20% !important;
}
.filterWidget .editFilterFieldDiv{
  margin-top: 10px !important;
} */



/**********************************************************************/
.filterFlavorCombo {
  width: 85% !important;
}

.filterPanelCombo {
  width: 85% !important;

}

.filterBaseCombo {
  width: 85% !important;
}

.saveFilterDataField {
  width: 80% !important;
}

.editFilterDataField {
  width: 85% !important;
}

.newFilter {
  right: 20% !important;
  float: right !important;
  margin-top: -30px !important;
  margin-right: 5px !important;
  position: none !important;
}

.saveBtnFilter {
  right: 20% !important;
}

.filterApplyBtn {
  right: 5px;
  bottom: 10px;
  position: fixed;
}

/* .editFilterFieldDiv{
  margin-top: 10px !important;
} */

.filterClearIcon:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.filterBaseCombo .LiComboBox_disabled,
.filterPanelCombo .LiComboBox_disabled {
  opacity: 0.5;
}

.dashboardGridLayoutDiv::-webkit-scrollbar {
  height: 7px !important;
}

.defaultFlavorChkbox {
  padding-top: 10px;
  padding-left: 20px;
}

.defaultFlavorChkbox .liCheckContainer label {
  padding-top: 2px !important;
}

.dashboardGridLayoutDiv .react-transform-component {
  width: unset !important;
  height: unset !important;
  position: unset !important;
  overflow: unset !important;
}

.dashboardGridLayoutDiv .react-transform-element {
  width: unset !important;
  height: unset !important;
}

.SliderForLayout {
  width: 30% !important;
}


/*
*for making the whole svg color
*/

.LightTheme .widgetValueDiv svg {
  background-color: var(--lighttheme-black-variant4) !important;
}

.DarkTheme .widgetValueDiv svg {
  background-color: var(--darktheme-black-variant2) !important;
}